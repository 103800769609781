import './main.css'

// Load Splide first
import { Splide } from '@splidejs/splide';
import { URLHash } from '@splidejs/splide-extension-url-hash';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '@splidejs/splide/css/core';
window.splide = Splide;
window.autoScroll = AutoScroll;

const splides = document.querySelectorAll('.splide.photo');
for (var i = 0; i < splides.length; i++) {
	new Splide(splides[i], {
		type: 'loop',
		pagination: true,
		arrows: false,
	}).mount();
}

import 'lazysizes'
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'

Alpine.plugin(focus)
Alpine.plugin(collapse)
Alpine.plugin(intersect)
window.Alpine = Alpine


// Alpine global data store
Alpine.store('data', {
  activeAnchor: null
})

Alpine.start()

import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css';
const lightbox = GLightbox({})
